<template>
  <div class="content mt-5" style="min-height: 600px">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-4">
                            <div class="card p-4">
                                
                                    <h4 class="card-title">Iniciar sesión</h4>
                                
                                <div class="card-body">
                                    <div id="login">
                                        <div class="preloader" v-if="preloader"></div>
                                    <form @submit.prevent="auth" v-if="!preloader">
                                        
                                        <div class="row">
                                           <div class="col-md-12" v-if="errorLogin !== ''">
                                                  <p class="alert alert-danger" role="alert" > {{errorLogin}} </p>
                                            </div>
                                            
                                            <div class="col-md-12 pl-1">
                                                <div class="form-group">
                                                    <label for="email">Ingrese su email</label>
                                                    <input type="email" class="form-control" placeholder="Correo electrónico" v-model="email" required>
                                                </div>
                                            </div>
                                            <div class="col-md-12 pl-1">
                                                <div class="form-group">
                                                    <label for="password">Ingrese su clave</label>
                                                    <input type="password" class="form-control" placeholder="Contraseña" v-model="password" required>
                                                </div>
                                            </div>
                                        </div>
                                      
                    
                                        <button type="submit" class="btn btn-info btn-fill pull-right">Ingresar</button>
                                        <div class="clearfix"></div>
                                       <!-- <p><a href="#" @click.prevent="recoverPass" id="passRec">Recuperar contraseña</a> </p>-->
                                    </form>
                                    </div>
                                    <!--
                                    <form method="POST" :class="statusRecoverPass" id="formRecPass">
                                    <div class="col-md-12 pl-1">
                                                <div class="form-group">
                                                    <label for="email">Ingrese su email</label>
                                                    <input type="email" class="form-control" placeholder="Correo electrónico" name="email">
                                                </div>
                                            </div>
                                            <button type="submit" class="btn btn-info btn-fill pull-right">Recuperar clave</button>
                                            
                                    </form>-->
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
data(){
    return {
            email: '',
            password: '',
            errorLogin: '',
            preloader: false,
            formRecoverPass: false
    }
},
methods:{
    ...mapActions(['login']),
    async auth(){
        this.preloader = true
        const res = await this.login({email: this.email, password: this.password})
        this.preloader = false
        this.errorLogin = !this.res ? 'Error, credenciales incorrectas.' : ''
    },
    recoverPass(){
        this.formRecoverPass = this.formRecoverPass ? false : true
    }
},
computed:{
    ...mapState('articles', ['error']),
    statusRecoverPass(){
        return this.formRecoverPass ? '' : 'd-none'
    }
}
}
</script>

<style>

</style>