<template>
  <LoginComponent />
</template>

<script>
import LoginComponent from '../components/LoginComponent'
export default {
    components:{
        LoginComponent
    }

}
</script>

<style>

</style>