<template>
        <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card strpied-tabled-with-hover">
                                <div class="card-header ">
                                    <h4 class="card-title">Todas las ordenes </h4>
                                    <p>Podrás listar y ver ordenes
                                        <div style="float: right;">
                                            <!--<select style="height: 35px" v-model="ebookOption">
                                                <option value="0">Seleccione un ebook...</option>
                                                <option value="2">Contratos electrónicos</option>
                                            </select>
                                            <input type="date" v-model="from" style="margin: 20px; height: 35px" >
                                            <input type="date" v-model="to" style="height: 35px" >-->
                                            <a :href="`https://ebook.iadpi.com.ar/ordercsv=${ebookOption}&from=${from}&to=${to}`"> 
                                                <img src="assets/img/csv.jpg" width="45" style="margin-left: 15px" alt="csv" />
                                            </a>
                                        </div>

                                       
                                    </p>
                                     <p class="text-danger">{{error ? 'No hay coincidencias con la búsqueda...' : ''}}</p>
                                </div>
                                <div class="tabla-categorias">
                                <div class="card-body table-full-width table-responsive">
                                    <div class="preloader mt-3" v-if="!orders"></div>
                                    <table class="table table-hover" :class="!orders ? 'd-none' : ''">
                                        <thead>
                                            <th>Id</th>
                                            <th>Usuario</th>
                                            <th>Moneda</th>
                                            <th>Total en ARS</th>
                                            <th>Acción</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="order in orders" :key="order.id">
                                                <td>{{order.id}}</td>
                                                <td>{{order.name}} {{order.lastname}}</td>
                                                <td>{{order.currency}}</td>
                                                <td>{{order.total}}</td>
                                                
                                                <td><router-link :to="`/orden/${order.id}`"><i class="fa fa-eye text-info mr-2 lead" style="padding-top: 1.3px; margin-left: 20px; cursor: pointer; margin-top: 7px"></i></router-link></td>
                                            </tr>
                                          
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
        </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
data(){
        return {
            ebookOption: 0,
            from: null,
            to: null,
            error: this.$route.params.error
        }
    },
methods:{
    ...mapActions('orders',['getOrders'])
},
created(){
    
    this.getOrders()
    
},
computed:{
    ...mapState('orders', ['orders']),
    statusPreloader(){
        !this.orders ? 'd-none' : ''
    }
    
}
}
</script>

<style>
.tabla-categorias{
    margin: 0 auto; 
    width: 90%; 
    margin-left: 8rem;
}

@media (max-width: 764px) {
    .tabla-categorias{
        margin: 0 auto; 
        width: 100%; 
        margin-left: 0;
    }
}
</style>