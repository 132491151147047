<template>
        <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="preloader mt-3" v-if="!order"></div>
                            <div class="card strpied-tabled-with-hover" v-if="order">
                                <div class="card-header ">
                                    <h4 class="card-title">Orden Nro.: {{order.id}} <button v-if="order.status != 1" style="float: right; cursor: pointer" class="m-3 btn btn-primary" @click="completeOrder({id: order.id, status: 1})">Pasar a completada</button></h4>
                                    
                                    
                                </div>
                                <div class="tabla-categorias">
                                <div class="card-body table-full-width table-responsive"  >
                                    
                                    <h5 class="mt-5">Detalle de la orden</h5>
                                    <table class="table" v-if="order">
                                        <thead>
                                            <th>Id</th>
                                            <th>Usuario</th>
                                            <th>Moneda</th>
                                            <th>Total en ARS</th>
                                            <th>Estado actual</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                               <td>{{order.id}}</td>
                                                <td>{{order.name}} {{order.lastname}}</td>
                                                <td>{{order.currency}}</td>
                                                <td>{{order.total}}</td>
                                                <td>{{order.status == 1 ? 'Completada' : 'Cancelada'}}</td>
                                            </tr>
                                          
                                        </tbody>

                                    
                                    </table>
                                    <h5 class="mt-5">Listado de ebooks de la orden</h5>
                                     <table class="table" v-if="ebooksOrder">
                                        <thead>
                                            <th>Miniatura</th>
                                            <th>Titulo</th>
                                            <th>Autor del libro</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="ebook in ebooksOrder" :key="ebook.id">
                                               <td><img :src="ebookImgPath+ebook.image_uri" width="50" alt="ebook" /></td>
                                                <td>{{ebook.title}}</td>
                                                <td>{{ebook.author}}</td>
                                                
                                            </tr>
                                          
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
        </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
data(){
        return {
            ebookImgPath: `${process.env.VUE_APP_API_URL}/storage/images/`
            
        }
    },
methods:{
    ...mapActions('orders',['getOrder', 'getEbooksOrder', 'completeOrder'])
},
async created(){
    
    await this.getOrder({id: this.$route.params.id})
    await this.getEbooksOrder({id: this.$route.params.id}) 
    
},
computed:{
    ...mapState('orders', ['order', 'ebooksOrder']),
    statusPreloader(){
        !this.order ? 'd-none' : ''
    }
    
}
}
</script>

<style>
.tabla-categorias{
    margin: 0 auto; 
    width: 90%; 
    margin-left: 8rem;
}

@media (max-width: 764px) {
    .tabla-categorias{
        margin: 0 auto; 
        width: 100%; 
        margin-left: 0;
    }
}
</style>