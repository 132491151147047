<template>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Crear ebook</h4>
                                </div>
                                <div class="card-body">
                                    <div class="preloader" v-if="preloader"></div>
                                    <p v-if="preloader" class="text-center mt-2">Cargando...</p>
                                    <form @submit.prevent="create" id="editeUser" v-if="!preloader">
                                        <div class="row px-md-2">
                                            <div class="col-md-12 p-md-1">
                                                <div class="form-group">
                                                    <label>Título</label>
                                                    <input type="text" class="form-control"  v-model="title" required>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Descripción</label>
                                                     <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                               <div class="form-group">
                                                    <label>Categoría</label>
                                                    <select v-model="category_id" class="form-control" required>
                                                        <option v-for="category in categories" :key="category.id" :value="category.id"> 
                                                            {{category.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6 p-md-1">
                                                <div class="form-group">
                                                    <label>Precio</label>
                                                    <input type="text" class="form-control"  v-model="price" required>
                                                </div>
                                            </div>
                                            <div class="col-md-6 p-md-1">
                                                <div class="form-group">
                                                    <label>Autor</label>
                                                    <input type="text" class="form-control"  v-model="author" required>
                                                </div>
                                            </div>
                                            <div class="col-md-6 p-md-1">
                                                <div class="form-group">
                                                    <label>Edición</label>
                                                    <input type="text" class="form-control"  v-model="edition" required>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Imagen</label>
                                                    <input type="file"  @change="fileChange()" ref="file" class="form-control" name="imagen">
                                                </div>
                                            </div>
                                             <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Ebook PDF</label>
                                                    <input type="file"  @change="pdfChange()" ref="pdf" class="form-control" name="pdf">
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-info btn-fill pull-right">Crear ebook</button>
                                        <div class="clearfix"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import { mapActions, mapState } from 'vuex';
import router from '../router'
 export default {
        components: {
            // Use the <ckeditor> component in this view.
            ckeditor: CKEditor.component
        },
        data() {
            return {
                editor: ClassicEditor,
                editorConfig: {
                    
                    plugins: [
                        EssentialsPlugin,
                        BoldPlugin,
                        ItalicPlugin,
                        LinkPlugin,
                        ParagraphPlugin,
                        CodeBlock,
                        Font,
                        Image,
                        Base64UploadAdapter,
                        ImageUpload,
                        MediaEmbed 


                    ],

                    toolbar: {
                        items: [
                            'bold',
                            'italic',
                            'underline',
                            'link',
                            'undo',
                            'redo',
                            'codeBlock',
                            'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor',
                            'imageUpload',
                            'mediaEmbed'
                           
                            
                        ]
                    }
                },
                title: '',
                description: '',
                price: '',
                author: '',
                edition: '',
                category_id: 1,
                file: '',
                imageArticle: '',
                file: '',
                pdf: '',
                preloader: false,
            };
        },
        computed:{
            ...mapState('articles', ['article', 'error', 'message']),
            ...mapState('categories', ['categories'])
        },
        methods:{
            ...mapActions('articles', ['createArticle', 'uploadImage', 'uploadPdf']),
            ...mapActions('categories', ['getCategories']),
            fileChange(){
                this.file = this.$refs.file.files[0]
            },
            pdfChange(){
                this.pdf = this.$refs.pdf.files[0]
            },
            async create(){
                try {
                    this.preloader = true
                    const res = await this.createArticle({
                        title: this.title, 
                        isbn: '',
                        description: this.description,
                        author: this.author,
                        edition: this.edition,
                        price: this.price,
                        image: this.imageArticle,
                        category_id: this.category_id, 
                        discount: 0
                    })
                    
                    if(res.status && this.file !== ''){
                        const upload = {file: this.file, articleId: res.articleId}
                        await this.uploadImage(upload)
                        if(this.pdf !== ''){
                            const pdfUpload = {file: this.pdf, articleId: res.articleId}
                            await this.uploadPdf(pdfUpload)
                        }
                    }
                    
                    res.status ? router.push('/articulos') : ''
                        
                    
                    this.preloader = false
                } catch (error) {
                    console.log(error)
                }
            }
            
        },
         async created(){
            this.preloader = true
            await this.getCategories()
            this.preloader = false
         },
         mounted(){
            const { userId } = JSON.parse(localStorage.getItem('userIadpi'))
            this.userId = userId
        }
}
</script>

<style>

</style>