<template>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Editar ebook</h4>
                                </div>
                                <div class="card-body">
                                    <div class="preloader" v-if="preloader"></div>
                                    <form @submit.prevent="edite" v-if="!preloader">
                                        <input type="hidden" name="id" value="">
                                        <div class="row px-md-2">
                                            <div class="col-md-12 p-md-1">
                                                <div class="form-group">
                                                    <label>Título</label>
                                                    <input type="text" class="form-control"  v-model="title" required>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Descripción</label>
                                                    <ckeditor :editor="editor" v-model="description" :config="editorConfig" tag-name="textarea"></ckeditor>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Categoría</label>
                                                    <select v-model="category_id" class="form-control" required>
                                                        <option v-for="category in categories" :key="category.id" :value="category.id" :selected="article.category_id == category.id ? true : ''"> 
                                                            {{category.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6 p-md-1">
                                                <div class="form-group">
                                                    <label>Precio</label>
                                                    <input type="text" class="form-control"  v-model="price" required>
                                                </div>
                                            </div>
                                             <div class="col-md-6 p-md-1">
                                                <div class="form-group">
                                                    <label>Autor</label>
                                                    <input type="text" class="form-control"  v-model="author" required>
                                                </div>
                                            </div>
                                             <div class="col-md-6 p-md-1">
                                                <div class="form-group">
                                                    <label>Edición</label>
                                                    <input type="text" class="form-control"  v-model="edition">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                     <label>Imagen actual:</label> <br>    
                                                    <img :src="srcImage" alt="imagen articulo" class="border img-fluid mb-3" width="150" height="200" />
                                                    <input type="file"  @change="fileChange()" ref="file" class="form-control" name="imagen">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                               <div class="form-group">
                                                    <label>Ebook PDF</label><br> 
                                                    <a :href="previewPdf" target="_blank"><img src="/assets/img/pdf.png" alt="imagen articulo" class="border img-fluid mb-3" width="150" height="200" /></a>
                                                    <input type="file"  @change="pdfChange()" ref="pdf" class="form-control" name="pdf">
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <button type="submit" class="btn btn-info btn-fill pull-right">Editar ebook</button>
                                        <div class="clearfix"></div>
                                    </form>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';



import { mapActions, mapState } from 'vuex';
import router from '../router';
 export default {
        components: {
            // Use the <ckeditor> component in this view.
            ckeditor: CKEditor.component
        },
        data() {
            return {
                editor: ClassicEditor,
                title: '',
                description: '',
                price: '',
                author: '',
                edition: '',
                category_id: '',
                article_id: '',
                file: '',
                pdf: '',
                previewPdf: '',
                imageArticle: '',
                srcImage: '',
                url: process.env.VUE_APP_API_URL,
                editorConfig: {
                    
                    plugins: [
                        EssentialsPlugin,
                        BoldPlugin,
                        ItalicPlugin,
                        LinkPlugin,
                        ParagraphPlugin,
                        CodeBlock,
                        Font,
                        Image,
                        Base64UploadAdapter,
                        ImageUpload,
                        MediaEmbed 


                    ],

                    toolbar: {
                        items: [
                            'bold',
                            'italic',
                            'underline',
                            'link',
                            'undo',
                            'redo',
                            'codeBlock',
                            'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor',
                            'imageUpload',
                            'mediaEmbed'
                           
                            
                        ]
                    }
                },
                preloader: false
            };
        },
        computed:{
            ...mapState('articles', ['article']),
            ...mapState('categories', ['categories'])
            
        },
        methods:{
            ...mapActions('articles', ['getOne', 'editeArticle','uploadImage', 'uploadPdf']),
            ...mapActions('categories', ['getCategories']),
            fileChange(){
                this.file = this.$refs.file.files[0]
            },
            pdfChange(){
                this.pdf = this.$refs.pdf.files[0]
            },
            async edite(){
                this.preloader = true
                 const res = await this.editeArticle({
                     id: this.article_id,
                     title: this.title, 
                     isbn: '',
                     description: this.description,
                     author: this.author,
                     edition: this.edition,
                     price: this.price,
                     image: this.imageArticle,
                     category_id: this.category_id, 
                     discount: 0
                     
                });

                
                if(res.status && this.file !== ''){
                    const upload = {file: this.file, articleId: this.article.id}
                    const resImg = await this.uploadImage(upload)
                    this.imageArticle = resImg.path
                    this.file = ''
                   
                }

                if(this.pdf !== ''){
                    const pdfUpload = {file: this.pdf, articleId: this.article_id}
                    await this.uploadPdf(pdfUpload)
                    this.pdf = ''
                }

                res.status ? router.push('/articulos') : ''

                this.preloader = false
            }
        },
        async created(){
            this.preloader = true
            await this.getOne(this.$route.params.id)
            this.title = this.article.title
            this.description = this.article.description
            this.price = this.article.price
            this.author = this.article.author
            this.edition = this.article.edition
            this.article_id = this.article.id
            this.category_id = this.article.category_id
            this.imageArticle = this.article.image_uri
            this.srcImage = `${process.env.VUE_APP_API_URL}/storage/images/${this.imageArticle}`
            this.previewPdf =  `${process.env.VUE_APP_API_URL}/storage/ebooks/${this.article.pdf_uri}`

            await this.getCategories()

            this.preloader = false
            
        },
        mounted(){
            const { userId } = JSON.parse(localStorage.getItem('userIadpi'))
            this.userId = userId
        }
}
</script>

<style>

</style>