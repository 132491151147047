<template>
  <InputCouponComponent coupon="" title="Crear cupón" />
</template>

<script>
import InputCouponComponent from '../components/InputCoupon'
export default {
    components:{
        InputCouponComponent
    },
    methods:{
        
    }
}
</script>

<style>

</style>