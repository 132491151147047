<template>
        <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card strpied-tabled-with-hover">
                                <div class="card-header ">
                                    <h4 class="card-title">Todas las categorías </h4>
                                    <p>Podrás crear, listar y editar categorías
                                    <router-link to="/crear-categoria">
                                        <p class="float-right btn btn-info btn-fill"><b> + Nueva categoría</b></p>
                                    </router-link></p>
                                    
                                </div>
                                <div class="tabla-categorias">
                                <div class="card-body table-full-width table-responsive">
                                    <div class="preloader mt-3" v-if="!categories"></div>
                                    <table class="table table-hover" :class="!categories ? 'd-none' : ''">
                                        <thead>
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Acción</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="category in categories" :key="category.id">
                                                <td>{{category.id}}</td>
                                                <td>{{category.name}}</td>
                                                <td><router-link :to="`/editar-categoria/${category.id}`"><i class="fa fa-edit text-info mr-2 lead" style="padding-top: 1.3px; margin-left: 20px; cursor: pointer; margin-top: 7px"></i></router-link></td>
                                            </tr>
                                          
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
        </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
data(){
        return {
            
        }
    },
methods:{
    ...mapActions('categories',['getCategories'])
},
created(){
    
    this.getCategories()
    
},
computed:{
    ...mapState('categories', ['categories']),
    statusPreloader(){
        !this.categories ? 'd-none' : ''
    }
    
}
}
</script>

<style>
.tabla-categorias{
    margin: 0 auto; 
    width: 90%; 
    margin-left: 8rem;
}

@media (max-width: 764px) {
    .tabla-categorias{
        margin: 0 auto; 
        width: 100%; 
        margin-left: 0;
    }
}
</style>