<template>
  <InputCategoryComponent :category="categoryName" title="Editar categoría" />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import InputCategoryComponent from '../components/InputCategory'
export default {
    components:{
        InputCategoryComponent
    },
    data(){
      return{
        categoryName : ''
      }
    },
    methods:{
        ...mapActions('categories',['getCategories'])
    },
    computed:{
      ...mapState('categories',['categories'])
    },
    async created(){
        /*
        const dataCats = await this.getCategories()
        const category = dataCats.find(cat => cat.id == this.$route.params.id)
        this.categoryName = category.name*/
      
    }
}
</script>
