<template>
  <InputCategoryComponent category="" title="Crear categoría" />
</template>

<script>
import InputCategoryComponent from '../components/InputCategory'
export default {
    components:{
        InputCategoryComponent
    },
    methods:{
        
    }
}
</script>

<style>

</style>