<template>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Editar usuario</h4>
                                </div>
                                <div class="card-body">
                                    <form @submit.prevent="save">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Nombre</label>
                                                    <input type="text" class="form-control"  v-model="name"  required>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group mr-md-2">
                                                    <label>Apellido</label>
                                                    <input type="text" class="form-control"  v-model="lastname" required>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group ml-md-2">
                                                    <label>DNI o CUIT</label>
                                                    <input type="text" class="form-control"  v-model="dni_cuit" required>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group mr-md-2">
                                                    <label>Dirección</label>
                                                    <input type="text" class="form-control" v-model="adress">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group ml-md-2">
                                                    <label>Localidad</label>
                                                    <input type="text" class="form-control" v-model="location">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group mr-md-2">
                                                    <label>Provincia</label>
                                                    <select v-model="city" class="form-control" >
                                                        <option value="1">CABA</option>
                                                        <option value="2">Buenos Aires</option>
                                                        <option value="3">Catamarca</option>
                                                        <option value="4">Chaco</option>
                                                        <option value="5">Chubut</option>
                                                        <option value="6">Córdoba</option>
                                                        <option value="7">Corrientes</option>
                                                        <option value="8">Entre Ríos</option>
                                                        <option value="9">Formosa</option>
                                                        <option value="10">Jujuy</option>
                                                        <option value="11">La Pampa</option>
                                                        <option value="12">La Rioja</option>
                                                        <option value="13">Mendoza</option>
                                                        <option value="14">Misiones</option>
                                                        <option value="15">Neuquén</option>
                                                        <option value="16">Río Negro</option>
                                                        <option value="17">Salta</option>
                                                        <option value="18">San Juan</option>
                                                        <option value="19">San Luis</option>
                                                        <option value="20">Santa Cruz</option>
                                                        <option value="21">Santa Fe</option>
                                                        <option value="22">Santiago del Estero</option>
                                                        <option value="23">Tierra del Fuego</option>
                                                        <option value="24">Tucumán</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group ml-md-2">
                                                    <label>País</label>
                                                    <select v-model="country" class="form-control" >
                                                        <option value="1">Argentina</option>
                                                        <option value="2" selected>Otro</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group mr-md-2">
                                                    <label>Teléfono</label>
                                                    <input type="number" class="form-control"  v-model="phone">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group ml-md-2">
                                                    <label>Permisos</label>
                                                    <select v-model="rol" class="form-control" >
                                                        <option value="0">Usuario</option>
                                                        <option value="1" selected>Administrador</option>
                                                    </select>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group ml-md-2">
                                                    <label>Estado</label>
                                                    <select v-model="status" class="form-control" >
                                                        <option value="1">Habilitado</option>
                                                        <option value="0">Deshabilitado</option>
                                                    </select>
                                                    
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-info btn-fill pull-right">Editar usuario</button>
                                        <div class="clearfix"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    data(){
        return { 
            name: '',
            lastname: '',
            dni_cuit: '',
            location: '',
            city: '',
            country: '',
            adress: '',
            phone: '',
            rol: '',
            status: ''
        }
    },
    computed:{
        ...mapState('users', ['user'])
    },
    methods:{
        ...mapActions('users', ['getUser', 'editUser']),
        async save(){

            try {
                const id = this.$route.params.id
                if(id){
                    await this.editUser({ id: id, name: this.name, lastname: this.lastname, dni_cuit: this.dni_cuit, adress: this.adress, location: this.location, city_id: this.city, country_id: this.country, phone: this.phone, role: this.rol, status: this.status })
                    return
                }
            } catch (error) {
                console.log(error)
            }
            
        }
    },
    async created(){
        await this.getUser({id: this.$route.params.id})
        this.name = this.user.name
        this.lastname = this.user.lastname
        this.email = this.user.email
        this.dni_cuit = this.user.dni_cuit
        this.adress = this.user.adress
        this.phone = this.user.phone
        this.location = this.user.location
        this.city = this.user.city_id
        this.country = this.user.country_id
        this.rol = this.user.role
        this.status = this.user.status
    }
}
</script>

<style>

</style>