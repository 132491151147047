<template>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">{{title}}</h4>
                                </div>
                                <div class="card-body">
                                    <div class="preloader" v-if="preloader"></div>
                                    <form @submit.prevent="save" id="editeUser" v-if="!preloader">
                                        <input type="hidden" name="id" value="">
                                        <div class="row px-md-2">
                                            <div class="col-md-6 p-md-1">
                                                <div class="form-group">
                                                    <label>Nombre</label>
                                                    <input type="text" class="form-control"  v-model="name" placeholder="RRTFGG" required>
                                                </div>
                                                
                                            </div>
                                             <div class="col-md-6 p-md-1">
                                                 <div class="form-group">
                                                    <label>Descuento en %</label>
                                                    <input type="number" class="form-control"  v-model="percentage"  placeholder="10" required>
                                                </div>
                                                
                                            </div>
                                             <div class="col-md-6 p-md-1">
                                                <div class="form-group">
                                                    <label>Expiración</label>
                                                    <input type="text" class="form-control"  placeholder="DD/MM/AAAA" v-model="exp">
                                                </div>
                                                
                                            </div>
                                             <div class="col-md-6 p-md-1">
                                                <div class="form-group">
                                                    <label>Limite</label>
                                                    <input type="number" class="form-control"  v-model="lot"  placeholder="100">
                                                </div>
                                                
                                            </div>
                                            <div class="col-md-6 p-md-1">
                                                <div class="form-group">
                                                    <label>Importe Máximo (nro. entero)</label>
                                                    <input type="number" class="form-control"  v-model="max"  placeholder="100">
                                                </div>
                                                
                                            </div>
                                           
                                        </div>
                                        <button type="submit" class="btn btn-info btn-fill pull-right">{{title}}</button>
                                        <div class="clearfix"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data(){
        return {
            preloader: false,
            name: '',
            percentage: 0,
            exp: '',
            lot: '',
            max: null,
            couponT: false
        }
    },
    props:{
        coupon: String,
        title: String
    },
    methods:{
        ...mapActions('coupons', ['createCoupon', 'editeCoupon', 'getCoupons']),
        async save(){

            try {
                const id = this.$route.params.id
                if(id){
                await this.editeCoupon({ id: id, name: this.name, percentage: this.percentage, expiration_date: this.exp, lot: this.lot, max_purchase: this.max })
                return
            }
                await this.createCoupon({ name: this.name, percentage: this.percentage, expiration_date: this.exp, lot: this.lot, max_purchase: this.max })

            } catch (error) {
                console.log(error)
            }
            
        }
    },
    async created(){
      
        if(this.$route.params.id){
            const coups = await this.getCoupons()
            this.couponT = coups.find(cp => cp.id == parseInt(this.$route.params.id))
        }
        
        this.name = this.couponT ? this.couponT.name : ''
        this.percentage = this.couponT ? this.couponT.percentage : ''
        this.exp = this.couponT ? this.couponT.expiration_date : ''
        this.lot = this.couponT ? this.couponT.lot : ''
        this.max = this.couponT ? this.couponT.max_purchase : ''

    }

}
</script>

<style>

</style>