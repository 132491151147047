<template>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">{{title}}</h4>
                                </div>
                                <div class="card-body">
                                    <div class="preloader" v-if="preloader"></div>
                                    <form @submit.prevent="save" id="editeUser" v-if="!preloader">
                                        <input type="hidden" name="id" value="">
                                        <div class="row px-md-2">
                                            <div class="col-md-12 p-md-1">
                                                <div class="form-group">
                                                    <label>Nombre</label>
                                                    <input type="text" class="form-control"  v-model="name"  required>
                                                </div>
                                            </div>
                                           
                                        </div>
                                        <button type="submit" class="btn btn-info btn-fill pull-right">{{title}}</button>
                                        <div class="clearfix"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data(){
        return {
            preloader: false,
            name: ''
        }
    },
    props:{
        category: String,
        title: String
    },
    methods:{
        ...mapActions('categories', ['createCategory', 'editeCategory', 'getCategories']),
        async save(){

            try {
                const id = this.$route.params.id
                if(id){
                await this.editeCategory({id: id, name: this.name})
                return
            }
                await this.createCategory(this.name)

            } catch (error) {
                console.log(error)
            }
            
        }
    },
    async created(){
        
        if(this.$route.params.id){
            const dataCats = await this.getCategories()
            const category = dataCats.find(cat => cat.id == this.$route.params.id)
            this.name = category.name
        }else{
            this.name = ''
        }
        
    
    }

}
</script>

<style>

</style>