<template>
  <InputCouponComponent :coupon="couponName" title="Editar cupón" />
</template>

<script>
import { mapState } from 'vuex'
import InputCouponComponent from '../components/InputCoupon'
export default {
    components:{
        InputCouponComponent
    },
    data(){
      return{
        couponName : ''
      }
    },
    methods:{
        
    },
    computed:{
      ...mapState('coupons',['coupons'])
    },
    created(){
        /*const coupon = this.coupons.find(coupon => coupon.id === this.$route.params.id)
        this.coupon = coupon*/
         
        
    }
}
</script>

<style>

</style>