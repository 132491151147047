<template>
        <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card strpied-tabled-with-hover">
                                <div class="card-header ">
                                    <h4 class="card-title">Todos los usuarios </h4>
                                    <p>Podrás listar y editar usuarios
                                        <a href="https://ebook.iadpi.com.ar/usercsv"> 
                                            <img src="assets/img/csv.jpg" width="45" style="float: right; margin: 20px;" />
                                        </a>
                                    </p>
                                    
                                </div>
                                <div class="tabla-categorias">
                                <div class="card-body table-full-width table-responsive">
                                    <div class="preloader mt-3" v-if="!users"></div>
                                    <table class="table table-hover" :class="!users ? 'd-none' : ''">
                                        <thead>
                                            <th>Nombre y apellido</th>
                                            <th>Email</th>
                                            <th>Acción</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="user in users" :key="user.id">
                                                <td>{{user.name}} {{user.lastname}}</td>
                                                <td>{{user.email}}</td>
                                                <td><router-link :to="`/usuario/${user.id}`"><i class="fa fa-edit text-info mr-2 lead" style="padding-top: 1.3px; margin-left: 20px; cursor: pointer; margin-top: 7px"></i></router-link></td>
                                            </tr>
                                          
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
        </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
data(){
        return {
            
        }
    },
methods:{
    ...mapActions('users',['getUsers'])
},
created(){
    
    this.getUsers()
    
},
computed:{
    ...mapState('users', ['users']),
    statusPreloader(){
        !this.users ? 'd-none' : ''
    }
    
}
}
</script>

<style>
.tabla-categorias{
    margin: 0 auto; 
    width: 90%; 
    margin-left: 8rem;
}

@media (max-width: 764px) {
    .tabla-categorias{
        margin: 0 auto; 
        width: 100%; 
        margin-left: 0;
    }
}
</style>